<!--能源模块--布局主页-->
<template>
  <div id="facilityIndex">
    <div class="header">
      <my-indextitle :imgurl="imgurl">{{ title }}</my-indextitle>
      <my-navdar :sitetype="sitetype" :list="list"></my-navdar>
    </div>
    <div class="main">
      <keep-alive :exclude="cancelCache">
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import indexTitle from "../../components/Title/indexTitle";
import Navdar from "../../components/Navdar/horizontal";

export default {
  data() {
    return {
      sitetype: 2,
      flag: 'FACILITIES',
      cancelCache: ["terminaleditor", "fireEdit", "outputEdit"],
      img: require("../../../public/images/moduleimg/equipment.png"),
      list: JSON.parse(sessionStorage.getItem("sitemenu")) || [],
    };
  },
  watch: {
    // 监听多语言切换，导航栏数据同步切换
    "$store.getters.loading": {
      handler: function (newValue, oldValue) {
        const value = this.$route.query.Code;
        if (!newValue) {
          this.$store.commit("menu/SITE_MENU", value);
          this.list = this.$store.getters.siteMenu;
          this.changeCode();
        }
      },
      deep: true,
    },
  },
  // 获取名称
  computed: {
    title: function () {
      let name = localStorage.getItem("facilityBuildName");
      return name;
    },
    imgurl: function () {
      let type = this.$store.getters.siteType;
      return this.img;
    },
  },
  // 导航栏数据初始化
  mounted() {
    const value = this.$store.getters.loading;
    const Code = this.$route.query.Code;
    console.log("value",value);
    console.log("Code",Code);
    if (!value) {
      this.$store.commit("menu/SITE_MENU", Code);
      this.list = this.$store.getters.siteMenu;
      this.changeCode();
      console.log("Code",this.list );
    }
  },
  methods:{
    //改变菜单中code，加上设备标志让其唯一
    changeCode(){
      if(this.list!=null&&this.list.length>0){
        for(let i=0;i<this.list.length;i++){
          this.changeCodeChildren(this.list[i],this.flag);
        }
        console.log("changeCode", this.list);
      }
    },

    //递归函数，所有children处理
    changeCodeChildren(item,flag){
        if(item.code.startsWith("ENE_") || item.code.startsWith("INFO_")){
            return;
        }
        if(!item.code.startsWith(flag+"_")){
          item.code = flag+"_"+item.code;
        }
        if(item.children!=null&&item.children.length>0){
          for(let i=0;i<item.children.length;i++){
            this.changeCodeChildren(item.children[i],flag);
          }
        }       
    }
  },
  components: {
    "my-indextitle": indexTitle,
    "my-navdar": Navdar,
  },
};
</script>

<style scoped>
#facilityIndex {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px #bdbcbc;
  background: #ffffff;
  width: calc(100% - 40px);
  height: calc(100% - 30px);
  overflow: hidden;
}
.main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.header {
  height: 100px;
  padding: 0px 20px 0px 20px;
}
</style>